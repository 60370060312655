import React from "react";
import { useParams, Link } from "react-router-dom";
import { newsData } from "../../utils/NewsData";

const NewsArticle = () => {
  const { id } = useParams(); // Extracts the id from the URL
  const article = newsData.find(item => item.id === parseInt(id, 10));
  

  if (!article) {
    return (
      <div className="text-white py-12 px-[5%]">
        <div className="w-full mx-auto">
          <p>Article not found.</p>
        </div>
      </div>
    );
  }

  return (
    <div className="text-white py-12 px-[5%]">
      <div className="w-full mx-auto">
        {/* Breadcrumb Navigation */}
        <p className="font-BaiJamjuree text-lg mb-6">
          <Link to="/" className="hover:underline font-BaiJamjuree font-semibold">
            News & Insights
          </Link>{" "}
          / {article.title}
        </p>

        <h1 className="text-3xl md:text-4xl font-bold font-BaiJamjuree mb-4" >
          {article.title}
        </h1>

        <p className="text-gray-400 text-sm font-BaiJamjuree font-semibold mb-6">
          {article.date}  {article.location}
        </p>

        {article.content.map((paragraph, index) => (
          <div
            key={index}
            className="mb-4 leading-relaxed font-BaiJamjuree text-gray-300"
            dangerouslySetInnerHTML={{ __html: paragraph }}
          />
        ))}

        {/* <img
          src={article.img}
          alt="news"
          className="mx-auto mt-6 mb-6 rounded-lg"
        /> */}

        <Link
          to={article.ctaLink}
          target="_blank"
          rel="noopener noreferrer"
          className="text-3xl font-BaiJamjuree font-semibold hover:underline"
        >
          {article.ctaText} 
        </Link>
      </div>
    </div>
  );
};

export default NewsArticle;
