import React from 'react';
import { newsData } from '../../utils/NewsData.js';
import { useParams } from 'react-router-dom';
// import herobg from '../../assets/Images/News/herobg.png';

const HeroSection = () => {

 const { id } = useParams(); // Extracts the id from the URL
 const article = newsData.find(item => item.id === parseInt(id, 10)); 

  return (
    <div
      className="relative w-full h-[350px] md:h-[400px] flex items-center justify-center bg-center bg-cover bg-no-repeat  text-white"
      style={{ backgroundImage: `url(${(article?.imghero)})` }}
      
    >
      
      {/* This div is used to hide cutouts on left and right sides for very lage screens */}
      <div className="absolute top-0 left-0 bottom-0 right-0 1700px:bg-left-righ-shadow-gradient z-40"></div>
      {/* Overlay */}
      <div className="absolute inset-0 bg-black/40"></div>

      {/* Navigation Buttons */}
      <div className="absolute bottom-4 left-0 right-0 flex items-center justify-between w-full px-[5%]">
        {/* Left: Newsroom Title */}
        <h1 className="text-2xl md:text-5xl font-BaiJamjuree font-bold">
          Newsroom
        </h1>

        {/* Right: Buttons */}

        {/* <div className="flex space-x-3 font-BaiJamjuree ">
          <button className="px-4 py-2 text-white text-xl bg-opacity-80 hover:bg-opacity-100 transition rounded-md">
            ← Previous
          </button>
          <button className="px-4 py-2 text-white  text-xl bg-opacity-80 hover:bg-opacity-100 transition rounded-md">
            Next →
          </button>
        </div> */}
      </div>
    </div>
  );
};

export default HeroSection;
