import hero1 from '../assets/Images/News/herobg1.png';
import hero2 from "../assets/Images/News/herobg2.png";


export const newsData = [
      {
        id: 1,
        imghero: hero1,
        title: "ISA Consulting Group’s Partner “Reveal” Expands AI Innovation in Kosovo",
        date: "February 19, 2025",
        location: "PRISTINA, CHICAGO",
        content: [
          `<div><strong class="font-bold text-xl font-BaiJamjuree">ISA Consulting Group and Reveal's Strategic Partnership</strong><br />
           ISA Consulting Group, a leading enabler of technological advancement based in the US, operating remotely and on-site from 7 countries including Kosovo, has proudly partnered over the years with Reveal, a global leader in AI-powered eDiscovery, review, and investigations, to accelerate innovation and growth in the region. This collaboration highlights Reveal’s strategic expansion in Kosovo, reinforcing the country’s role as a key hub for European operations and global AI-driven innovation initiatives.</div>`,
    
          `<div><strong class="font-bold text-xl font-BaiJamjuree">The Creation of the 'Center of Excellence' in Prishtina</strong><br />
           Through this partnership, Reveal’s investment in Kosovo marks a significant milestone in the region’s tech ecosystem. With ISA Consulting, Reveal plans to create the 'Center of Excellence', located in the heart of Prishtina, which will rapidly become a cornerstone of Reveal's global operations. ISA Consulting, just as with all its partners, is focused on maximizing the region’s talent to drive AI and technological growth in collaboration with Reveal.</div>`,
    
          `<div><strong class="font-bold text-xl font-BaiJamjuree">Reveal's Commitment to Innovation in Kosovo</strong><br />
           “Kosovo's transformation into a vibrant technology center aligns perfectly with Reveal's vision for innovation and growth,” said Wendell Jisa, founder & CEO of Reveal. “The exceptional talent pool, coupled with the country's robust digital infrastructure, has enabled us to accelerate our AI and technology development initiatives while establishing a strong presence in Southeast Europe. The success we've achieved in Kosovo over the past 18 months has exceeded our expectations and reinforces our commitment to further investment in the region.”</div>`,
    
          `<div><strong class="font-bold text-xl font-BaiJamjuree">Kosovo's Thriving Tech Ecosystem and Strategic Location</strong><br />
           Kosovo’s thriving tech ecosystem, fueled by a young, skilled workforce and strong digital infrastructure, has created the ideal environment for Reveal’s growth. With a strategic location, rising international investment, and a booming startup scene, the region has become a key innovation hub in Southeast Europe. ISA Consulting recognizes Kosovo’s vast potential and remains committed to supporting Reveal, along with its other partners, in advancing AI-driven solutions and fostering cross-border partnerships.</div>`,
    
          `<div><strong class="font-bold text-xl font-BaiJamjuree">The Ideal Environment for Reveal's Growth in Kosovo</strong><br />
           "Kosovo's dynamic tech ecosystem, powered by a young, tech-savvy workforce where over 65% of the population is under 35, has proven ideal for Reveal's growth. The region's strategic location, robust digital infrastructure, and thriving startup scene—marked by a 15% surge in international investment and 30% growth in new ventures—have positioned the Center of Excellence as a key driver of innovation in Southeast Europe. This combination of talent, infrastructure, and strategic location has accelerated Reveal's ability to develop cutting-edge AI solutions while fostering valuable cross-border partnerships." </div>`,
    
          `<div><strong class="font-bold text-xl font-BaiJamjuree">Expansion Plans and Job Creation by ISA Consulting</strong><br />
           As part of this strategic partnership, ISA Consulting plans to expand its Kosovo office to more than 400 employees by 2026, with roles spanning advanced software development, AI research, customer success, marketing, and sales enablement. The company has benefited from substantial government support, including innovation grants and R&D incentives, which have played a key role in facilitating this growth. ISA Consulting is committed to supporting its partners in maximizing these opportunities and further strengthening the technological landscape in Kosovo.</div>`,
    
          `<div><strong class="font-bold text-xl font-BaiJamjuree">Revolutionizing the Legal Industry with AI Tools</strong><br />
           “This expansion underscores our dedication to empowering legal professionals with cutting-edge AI tools,” said Imer Perezic, Executive Vice President at Reveal. “As pioneers in the legal AI revolution, we take immense pride in delivering innovative solutions that seamlessly combine accessibility with transformative technology, reshaping the future of the legal industry on a global scale.”</div>`,
    
          `<div><strong class="font-bold text-xl font-BaiJamjuree">Kosovo's Growing Role as a Global Tech Hub</strong><br />
           The partnership between ISA Consulting and Reveal highlights the immense potential of Kosovo as a thriving technology hub. As Reveal continues to develop cutting-edge AI solutions and foster cross-border collaborations, ISA Consulting remains dedicated to ensuring the region becomes a global center for AI innovation and digital transformation.</div>`
        ],
        ctaText: "Check out Reveal's full article for more details",
        ctaLink: "https://www.revealdata.com/news/reveal-bolsters-global-presence-with-thriving-center-of-excellence-in-kosovo",
      //   img: reveal,
      },
      {
        id: 2,
        imghero: hero2,
        title: "ISA Consulting Introduces Health Insurance for Employees and Their Families",
        date: "",
        location: "",
        content: [
          `<div><strong class="font-bold text-xl font-BaiJamjuree">Introducing Comprehensive Health Insurance Coverage</strong><br />
           ISA Consulting is proud to announce a new benefit designed to support the well-being of our employees—comprehensive health insurance coverage. Effective from 01.03.2025, all employees, along with their close family members, will have access to this valuable coverage.</div>`,
    
          `<div><strong class="font-bold text-xl font-BaiJamjuree">Our Commitment to Employee Well-Being</strong><br />
           At ISA Consulting, we believe that taking care of our team goes beyond the workplace. Health and peace of mind are essential, and by offering this benefit, we aim to ensure our employees and their families have the support they need when it matters most. Whether for routine healthcare needs or unexpected medical situations, this coverage is a step toward a healthier, more secure future for our team.</div>`,
    
          `<div><strong class="font-bold text-xl font-BaiJamjuree">Employee Perspective: A Sense of Security</strong><br />
           We asked one of our employees, Anida Llapashtica, what she thinks about this new initiative. She shared, "Knowing that both my family and I are covered gives me such a sense of security. It’s reassuring to work for a company that genuinely cares about our well-being, not just as employees but as people with families to support."</div>`,
    
          `<div><strong class="font-bold text-xl font-BaiJamjuree">A Broader Commitment to Employee Support</strong><br />
           This initiative is part of our broader commitment to fostering a workplace where employees feel valued and supported. We understand that when employees are healthy and confident in their well-being, they can thrive both personally and professionally.</div>`,
    
          `<div><strong class="font-bold text-xl font-BaiJamjuree">Dedication to a Positive Work Environment</strong><br />
           ISA Consulting remains dedicated to creating a positive work environment—one where our employees and their families are cared for and supported every step of the way. </div>`,
        ],
        ctaText: "",
        ctaLink: "",
      //   img: reveal,
      },
    ];
    