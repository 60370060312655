import React from 'react';
import { Helmet } from 'react-helmet-async';
import HeroSection from '../Components/News/Hero';
import NewsArticle from '../Components/News/NewsArticle';

export const NewsDetail = () => {
  return (
    <main className="w-full">
      <Helmet>
        <title>News</title>
        <meta
          property="image"
          content="https://res.cloudinary.com/dtknqgojs/image/upload/v1720157911/ISA_Intro_video/ISA_Logo_dkzepx.svg"
        />
        <meta property="url" content="https://isaconsulting.com/" />
        <meta property="type" content="website" />
        <link rel="canonical" href="https://isaconsulting.com/" />
        <meta
          name="description"
          content="ISA offers end-to-end solutions in Digital Transformation, Digital Consulting and Business Process Services - supporting all Tech Stacks. Collectively we service a multitude of clients across industries and company verticals. We are a culmination of some of the brightest Full Stack Developers, Data Engineers, Architects, Project Managers, Quality Analysts, Strategists, spanning across multiple time zones."
          data-rh="true"
        />
        <meta
          property="og:description"
          content="ISA offers end-to-end solutions in Digital Transformation, Digital Consulting and Business Process Services - supporting all Tech Stacks. Collectively we service a multitude of clients across industries and company verticals. We are a culmination of some of the brightest Full Stack Developers, Data Engineers, Architects, Project Managers, Quality Analysts, Strategists, spanning across multiple time zones."
          data-rh="true"
        />
      </Helmet>
      <HeroSection />
      <NewsArticle />
      {/* <RelatedNews /> */}
    </main>
  );
};
